import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { usePartnersData } from '../../../partners/usePartnersData';

import discountTag from '../../../assets/landing/discount-tag.svg';

import './DiscountApplied.css';

const DiscountTagItem = ({ item }) => (
  <div className="Row Discount-applied-item">
    <img
      alt=""
      className="Discount-tag-icon"
      src={discountTag}
    />

    <span>{item.Description}</span>
  </div>
);

const DiscountApplied = () => {
  const { t } = useTranslation('quoting');
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const { isCostco, isGoodDog, isUSAA } = usePartnersData();
  const { rateSummary } = store;

  if (rateSummary.AllDiscounts.length === 0) {
    return null;
  }

  return (
    <div className="Discount-applied-container">
      <h3 className={'Text-title Discount-applied-title'
        + ' Text-center'}
      >
        {t((isGoodDog || isUSAA) ? 'discountDisclaimer' : 'discount',
          { count: rateSummary.AllDiscounts.length })}
        {isCostco ? '¹' : ''}
      </h3>

      <div className="Row Discount-applied-items">
        {rateSummary.AllDiscounts.map((item, index) => (
          <DiscountTagItem
            key={item?.Description?.replace(/\s/, '')}
            index={index}
            item={item}
          />
        ))}
      </div>
    </div>
  );
};

export default DiscountApplied;
