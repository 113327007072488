import { useCallback, useMemo } from 'react';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import { rateSummary } from '../actions';
import { hideLoading, validateCostcoMembership } from '../actions/partners';
import { LOADING_QUOTE_DATA } from '../actions/types';
import { PetType } from '../constants';
import { useFeeWaived, useFeeWaivedBackend } from './useFeatureFlag';

const useRateSummary = () => {
  const store = useSelector(({ quoting }) => quoting, shallowEqual);
  const dispatch = useDispatch();
  const isFeeWaived = useFeeWaived();
  const isFeeWaivedBackend = useFeeWaivedBackend();
  const {
    customerZipCode,
    data,
    effectiveDateCustom,
    parameters,
    partners,
    plans,
    quoteSelectedValues,
    sessionInformation: { nopCommerceUser, userEmail },
  } = store;

  const getSelectedRateBody = useMemo(() => {
    if (!data) {
      return {};
    }

    const {
      effectiveDate,
      groupCode,
      groupCodeDscr,
      petQuoteResponseList,
    } = data;

    const {
      costcoMembership,
      isCostco,
      partnerId,
      petPickupDate,
    } = partners;

    const petQuotes = [];

    petQuoteResponseList.forEach((quote) => {
      const selectedValues = quoteSelectedValues.find(
        (item) => item.quoteId === quote.petQuoteId,
      );

      if (!selectedValues) {
        return;
      }

      const selectedPackagePlan = quote.PrePackagedPlans
        .find((item) => item.Id === selectedValues.selectedPlanPackaged);

      let reimbursement = selectedValues.reimbursementId;
      let deductible = selectedValues.deductibleId;

      if (selectedPackagePlan) {
        reimbursement = selectedPackagePlan.ReimbursementId;
        deductible = selectedPackagePlan.DeductibleId;
      }

      const selectedPlanByIndex = plans[selectedValues.selectedPlanIndex];

      petQuotes.push({
        deductible,
        Id: quote.petQuoteId,
        Modifiers: quote.modifiers || [],
        PetAge: quote.petAgeName,
        PetBreed: quote.breedName,
        PetBreedId: quote.breedId,
        PetName: quote.petName,
        PetSex: quote.genderName,
        PetType: quote.petType === PetType.Dog.value
          ? PetType.Dog.name : PetType.Cat.name,
        plan: selectedPlanByIndex.id,
        reimbursement,
        selectedPlanIndex: selectedValues.selectedPlanIndex,
      });
    });

    const body = {
      DiamondClientId: nopCommerceUser.DiamonClientdId,
      EffectiveDate: effectiveDate,
      eMail: userEmail || parameters.email,
      employerName: '',
      GroupCode: groupCode,
      GroupCodeDscr: groupCodeDscr,
      IsEb: false,
      nopCommerceClientId: nopCommerceUser.CustomerNopCommerceId,
      Partner: {
        IsFeeWaived: costcoMembership.isFeeWaived,
        MembershipId: isCostco ? costcoMembership.membershipNumber : '',
        MembershipType: isCostco ? costcoMembership.membershipTypeName : '',
        PartnerGuid: partnerId || '',
      },
      PartnerGuid: partnerId || '',
      petQuotes,
      PickupDate: petPickupDate,
      quoteId: 1,
      TestEffectiveDate: effectiveDateCustom,
      ZipCode: customerZipCode,
    };

    return body;
  }, [
    data,
    effectiveDateCustom,
    nopCommerceUser.DiamonClientdId,
    nopCommerceUser.CustomerNopCommerceId,
    userEmail,
    parameters.email,
    customerZipCode,
    quoteSelectedValues,
    partners,
    plans,
  ]);

  const getSelectedRate = useCallback(async () => {
    const {
      costcoMembership: {
        membershipNumber,
        waivableFee,
        zipCode,
      },
    } = partners;

    if (membershipNumber && zipCode !== customerZipCode) {
      dispatch({ type: LOADING_QUOTE_DATA });

      await dispatch(validateCostcoMembership({
        customerZipCode,
        membershipNumber,
        rateSummaryBody: getSelectedRateBody,
        useFeeWaived: isFeeWaived,
        useFeeWaivedBackend: isFeeWaivedBackend,
        waivableFee,
      }));
    } else {
      await dispatch(rateSummary(getSelectedRateBody));
    }

    dispatch(hideLoading());
  }, [
    dispatch,
    getSelectedRateBody,
    partners,
    customerZipCode,
    isFeeWaivedBackend,
    isFeeWaived,
  ]);

  return { getSelectedRate, getSelectedRateBody };
};

export { useRateSummary };
