import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
} from 'react';
import FigoLoadingOverlay
from '../components/common/FigoLoadingOverlay';
import { apiCall } from '../util';

const FeatureFlagContext = createContext();

const FEATURE_FLAGS_LOADED = 'FEATURE_FLAGS_LOADED';
const FEATURE_FLAGS_FAILED = 'FEATURE_FLAGS_FAILED';
const FEATURE_FLAGS_REQUESTED = 'FEATURE_FLAGS_REQUESTED';

const INITIAL_STATE = {
  error: '',
  flags: {},
  loading: false,
};

function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FEATURE_FLAGS_FAILED:
      return {
        ...state,
        error: '',
        flags: {},
        loading: false,
      };
    case FEATURE_FLAGS_LOADED:
      return {
        ...state,
        error: '',
        flags: action.payload,
        loading: false,
      };
    case FEATURE_FLAGS_REQUESTED:
      return {
        ...state,
        error: action.payload,
        loading: true,
      };
    default:
      return state;
  }
}

export const FeatureFlagProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE);
  const {
    flags,
    loading,
  } = state;

  const getFeatureFlags = useCallback(async () => {
    try {
      dispatch({ type: FEATURE_FLAGS_REQUESTED });

      const response = await apiCall('customer/FeatureFlag');
      const payload = await response.json();
      const d2cFlags = Object.entries(payload)
        .filter(([key]) => key.startsWith('QuoteSites')
          || key.startsWith('QuoteSiteD2C'))
        .reduce((pre, [key, value]) => ({ ...pre, [key]: value }), {});
      dispatch({ payload: d2cFlags, type: FEATURE_FLAGS_LOADED });
    } catch (error) {
      dispatch({ payload: error, type: FEATURE_FLAGS_FAILED });
    }
  }, [dispatch]);

  useEffect(() => {
    getFeatureFlags();
  }, [getFeatureFlags]);

  return (
    <FeatureFlagContext.Provider value={flags}>
      {loading ? <FigoLoadingOverlay visible /> : null}
      {children}
    </FeatureFlagContext.Provider>
  );
};

const useFeatureFlag = (featureKey) => {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw Error('Feature Flag Context not found');
  }

  const featureFlags = context;
  const featureFlag = useMemo(
    () => !!featureFlags[featureKey],
    [featureFlags, featureKey],
  );
  return featureFlag;
};

const useShowFF = () => useFeatureFlag('QuoteSitesShowFF');
const useBeaconSalesforce = () => useFeatureFlag('QuoteSiteD2CMCPBeacon');
const useIndividualPurchases =
  () => useFeatureFlag('QuoteSiteD2CIndividualPurchases');
const useFeeWaived = () => useFeatureFlag('QuoteSiteD2CFeeWaived');
const useFeeWaivedBackend =
  () => useFeatureFlag('QuoteSiteD2CFeeWaivedBackend');

export {
  useBeaconSalesforce,
  useIndividualPurchases,
  useShowFF,
  useFeeWaived,
  useFeeWaivedBackend,
};
